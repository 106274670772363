
.frame-17-eUf {
    /*align-items: center;*/
    background-color: #ffffff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 2rem;
    width: 100%;
}

.frame-5-Ceb {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.frame-155-qQ3 {
    align-items: center;
    background-color: #f3f4f7;
    border-radius: 2rem;
    color: #2c2f4e;
    display: flex;
    flex-shrink: 0;
    font-family: Spoqa Han Sans Neo, 'Source Sans Pro';
    font-size: 0.8rem;
    font-weight: 700;
    height: 2.5rem;
    justify-content: center;
    line-height: 1.2575;
    white-space: nowrap;
    width: 4.3rem;
}

.frame-5-S8w {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.frame-155-6YF {
    align-items: center;
    background-color: #f3f4f7;
    border-radius: 2rem;
    color: #2c2f4e;
    display: flex;
    flex-shrink: 0;
    font-family: Spoqa Han Sans Neo, 'Source Sans Pro';
    font-size: 0.8rem;
    font-weight: 700;
    height: 2.5rem;
    justify-content: center;
    line-height: 1.2575;
    white-space: nowrap;
    width: 4.3rem;
}

.frame-5-xTy {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}


.frame-155-y3d {
    align-items: center;
    background-color: #f3f4f7;
    border-radius: 2rem;
    color: #bdbec9;
    display: flex;
    flex-shrink: 0;
    font-family: Spoqa Han Sans Neo, 'Source Sans Pro';
    font-size: 1.6rem;
    font-weight: 700;
    height: 5.2rem;
    margin-top: 2rem;
    justify-content: center;
    line-height: 1.2575;
    white-space: nowrap;
    width: 100%;
}
