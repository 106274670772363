.top-navigation-bar {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    gap: 10px;
    position: absolute;
    width: 100%;
    height: 70px;
    left: 0;
    top: 0;
    background: #FFFFFF;
    border-bottom: 1px solid #F1F1F3;
    z-index: 10000;
}