/* ScreenOuter */

.navbar {
}

.nav-header {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #F1F1F3;
    background-color: white;
}

.logo {
    width: auto;
    overflow: visible;
    float: left;
    padding-inline: 0 !important;
}

.logo a {
    display: inline-block;
    font-size: 20px;
    text-transform: capitalize;
}

.navbar-menu {
    float: left;
}

.ant-layout-header {
    padding-inline: 20px;
}

.navbar-menu .ant-layout-header {
    padding: 0 20px;
}

.navbar-menu .ant-menu-horizontal {
    border-bottom: 0;
}

.navbar-menu .ant-menu-item {
    padding-bottom: 0px;
}

.navbar-menu .nav-status-board {
    float: left;
}

.navbar-menu .rightMenu {
    float: right;
}

.menuButton {
    float: right;
    height: 32px;
    padding: 6px;
    margin-top: 14px;
    display: none;
    background: none;
    margin-right: 10px;
}

.ant-drawer-body {
    padding: 0;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
    display: inline-block;
    width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
    border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
    border-bottom-color: transparent;
}

.username {
    display: none;
    margin-left: 8px;
}

.pc-side-menu {
    display: block;
    background-color: #FCFCFD;
}

@media (max-width: 927px) {
    .menuButton {
        display: block;
        margin-right: 10px;
    }

    .nav-status-board,
    .pc-side-menu,
    .rightMenu {
        display: none;
    }

    .logo a {
        margin-left: -10px;
    }

    .ant-drawer-title > .brand-font {
        margin-bottom: 0;
    }

    .username {
        display: inline-block;
    }
}

/* ScreenOuter End */