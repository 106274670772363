
.price-table {
    display: flex;
}

@media (max-width: 927px) {
    .price-table {
        display: none;
    }
}
